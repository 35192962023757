//$white: #f2f1f1;
$white: #fff;
$active-link:#e6020d;
$pure-black: #030303;
$light-black: #fff;
$black: #edf2f9;
$navbar-fixed:#232e3c;
$red: #e50914;
$grey: #344050;
$blue-google: #1356c7;

// $main-font: 'Inter', sans-serif;
$main-font: 'Kanit", sans-serif';
$fakeflix-font: "Bebas Neue", cursive;


/*
[:::: admin theme ::::]

bg color #edf2f9
bg color2 #232e3c

text color : #344050
text color2 : #9da9bb

card - bg color : #f9fafd

button - bg color : #fff
button - border color : #fff
*/