// @import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800;900&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Kanit:wght@200&display=swap");
@import "./scss/mixin";
@import "./scss/variables";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Kanit", sans-serif;
}

body {
  font-family: $main-font;
  background-color: #fff;
  color: $white;
}

button,
input {
  font-family: "Kanit", sans-serif !important;
}

// Custom Bootstrap
.btn-danger {
  background-color: #e50914;
  color: #f2f2f2;
}

.btn-signin {
  margin-top: 2em;
  min-height: 60px;
  font-size: 15px;
}

input .form-control {
  background-color: red !important;
}

.text-danger {
  color: #e50914 !important;
}

.form-control {
  font-family: "Kanit", sans-serif;
}
