@import '../../scss/mixin';
@import '../../scss/variables';

.Navbar {
    position: fixed;
    box-sizing: border-box;
    z-index: 10;
    width: 100%;
    height: 70px;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 4vw;
    background-image: linear-gradient(to bottom,rgba(0,0,0,.7) 10%,rgba(0,0,0,0));
    transition: all 0.2s ease-out;

    &__fixed {
        background-image: none;
        background-color: $navbar-fixed;
        box-shadow: 0px -20px 11px 16px #0a0a0a;
    }

    &__logo {
        max-width: 40px;
        width: 100%;

        @include mq(600px) {
            max-width: 130px;
        }
    }

    &__primarynav,
    &__secondarynav {
        display: flex;
        align-items: center;
        margin: 0;
        padding: 0;
    }

    &__navlinks {
        margin-left: 2rem;

        &--link {
            display: inline-block;
            margin: 0 12px;
            font-size: 14px;
            //text-shadow: 2px 2px 6px rgba($color: $pure-black, $alpha: 0.9);

            @include mq('sm'){
                font-size: 16px;
            }
            @include mq('md'){
                font-size: 1.8vw;
            }
            @include mq('lg'){
                font-size: 1.2vw;
             //   text-shadow: 2px 2px 2px rgba($color: $black, $alpha: 0.45);
            }
            @include mq(1330px){
                font-size: 18px;
            }

            a {
                color: #f2f1f1;
                text-decoration: none;
                cursor: pointer;
                font-weight: 400;
                will-change: font-weight;
                transition: color .2s ease-in-out;

                &:hover {
                    color: #b3b3b3;
                }
            }

            .activeNavLink {
                color: $active-link;
                font-weight: 700;
                cursor: default;

                &:hover {
                    color: $white;
                }
            }
        }
    }

    &__primarynav {
        position: absolute;
        top: 70px;
        left: 0;
        margin: 0 auto;
        width: 100%;
        height: 45px;
        justify-content: center;
        pointer-events: none;
        transition: all 0.2s ease-in-out;

        @include mq(lg) {
            position: relative;
            display: flex;
            align-items: center;
            min-height: 40px;
            cursor: pointer;
            width: unset;
            height: unset;
            top: unset;
            left: unset;
            margin: unset;
            margin-left: 2rem;
            pointer-events: all;
        }

        &--scrolled {
            background-color: $black;
        }

        &.active {
            .Navbar__primarynav--caret {
                transform: rotate(180deg);
            }
        }

        .Navbar__navlinks--link {
            pointer-events: all;
            cursor: pointer;
        }

        &--caret {
            pointer-events: all;
            cursor: pointer;
            transition: transform 0.2s ease-out;
        }

        &--content {
            position: fixed;
            top: 0;
            left: 0;
            width: 100vw;
            height: 100vh;
            border: none;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            z-index: 1;
            background-color: rgba(0, 0, 0, 0.65);
            box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
            backdrop-filter: blur(10px);
            opacity: 0;
            transition: all 0.2s ease-out;

            @include mq(sm) {
                min-width: 180px;
            }

            @include mq(lg) {
                display: block;
                pointer-events: none;
                position: absolute;
                opacity: 0;
                top: 100px;
                right: 0;
                padding: 10px 0;
                min-width: auto;
                white-space: nowrap;
                border: 1px solid rgba(255,255,255,0.5);
                box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
            }

            &.active {
                opacity: 1;
                pointer-events: all;
            }

            li {
                color: #e5e5e5;
                padding: 4vw 0;
                text-decoration: none;
                display: block;
                cursor: pointer;
                transition: color .2s ease-in-out;

                &:hover {
                    color: #b3b3b3;
                }

                a {
                    display: block;
                }

                @include mq(lg) {
                    padding: 5px 15px;
                }
            }

            .activeNavLink {
                color: $white;
                font-weight: 700;
                cursor: default;

                &:hover {
                    color: $white;
                }
            }

            &-wrp {
                display: flex;
                flex-direction: column;
                justify-content: center;
                width: inherit;
                height: inherit;

                .Navbar__navlinks--link {
                    font-size: 16px;

                    @include mq('md') {
                        font-size: 1.8vw;
                    }
                    @include mq('lg') {
                        font-size: 1.2vw;
                    }
                }
            }
        }
    }

    &__secondarynav {
        margin-left: auto;
    }

    &__navprofile {
        position: relative;
        display: flex;
        align-items: center;
        cursor: pointer;

        &.active {
            .Navbar__navprofile--caret {
                transform: rotate(180deg);
            }
        }

        &--avatar {
            max-width: 30px;
            width: 100%;
            margin-right: 8px;

            @include mq(sm) {
                max-width: 40px;
            }
        }

        &--caret {
            transition: transform 0.2s ease-out;
        }

        &--content {
            display: block;
            pointer-events: none;
            position: absolute;
            opacity: 0;
            top: 100px;
            right: 0;
            padding: 10px 0;
            min-width: auto;
            white-space: nowrap;
            border: 1px solid rgba(255,255,255,0.5);
            background-color: rgba(0,0,0,0.9);
            box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
            z-index: 1;
            transition: all 0.2s ease-out;

            @include mq(sm) {
                min-width: 180px;
            }

            &.active {
                opacity: 1;
                top: 55px;
                pointer-events: all;
            }

            li {
                color: $white;
                padding: 5px 15px;
                text-decoration: none;
                display: block;

                &:hover {
                    text-decoration: underline;
                }
                a {
                    display: block;
                }
            }
        }
    }
}